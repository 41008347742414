import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
//import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { Observable } from "rxjs/Observable";

import { AuthenticationService } from '../services/service.index';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`,
                    'app-db': `${localStorage.getItem('currentState')}`
                }
            });
        } else {
            request = request.clone({
                setHeaders: {
                    'app-db': `${localStorage.getItem('currentState')}`
                }
            });
        }
        return next.handle(request);
    }
}