import { Productor } from '../../models/productor.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';
//import { UsuarioService } from '../usuario/usuario.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
const PARAMS = new HttpParams({
    fromObject: {
        action: 'opensearch',
        format: 'json',
        origin: '*'
    }
});
//import swal from 'sweetalert';
@Injectable({ providedIn: 'root' })
export class ProductorService {

    totalProductores: number = 0;
    public url = URL_SERVICIOS + 'productor';
    constructor(
        private http: HttpClient,
        //public _usuarioService: UsuarioService
    ) { }
    guardarProductor(productor: any) {

        if (productor.id !== null) {
            // actualizando
            let urla = URL_SERVICIOS + 'productor/actualizar/' + productor.id;
            return this.http.post(urla, productor)
                .pipe(map((resp: any) => {
                    return resp;
                }));
        } else {
            // creando
            return this.http.post(URL_SERVICIOS + 'productor/insertar', productor)
                .pipe(map((resp: any) => {
                    return resp;
                }));
        }
    }
    search(term: string) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .get(this.url + '/search', { params: PARAMS.set('search', term) }).pipe(
                map(response => response)
            );
    }
    obtenerPorId(id: string) {
        if (id === null) {
            return of([]);
        }
        return this.http
            .get(URL_SERVICIOS + 'productor/obtener/' + id).pipe(
                map(response => response)
            );
    }
    obtenerDetalle(id: number) {
        if (id === 0) {
            return of([]);
        }
        return this.http
            .get(this.url + '/detalle/' + id).pipe(
                map(response => response)
            );
    }

    obtenerPorRfc(rfc: string) {
        if (rfc === '') {
            return of([]);
        }
        return this.http
            .get(this.url + '/obtenerPorRfc/' + rfc).pipe(
                map(response => response)
            );
    }

}
