import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { URL_SERVICIOS } from '../config/config';
@Injectable({
    providedIn: 'root'
})
export class DataService {
    constructor(private httpClient: HttpClient) { }

    getData(recurso, paramas): Observable<any> {
        return this.httpClient.get(URL_SERVICIOS + recurso + paramas);
    };

    sortData(sortOptions): Observable<any> {
        //console.log(sortOptions);
        return this.httpClient.post(`${URL_SERVICIOS}/productor/listado`, sortOptions);
    };
    get(recurso: any, event: any): Observable<any> {
        var objFilter = [];
        if (event) {
            var filterGroups = event.args.filters;
            var filterInfo = "";
            for (var x = 0; x < filterGroups.length; x++) {
                let filterDataField = filterGroups[x].datafield;
                let filterGroup = filterGroups[x].filter;
                let filterOperator = filterGroup.operator;
                let filters = filterGroup.getfilters();
                let condition;
                let value;
                let type;
                let operator;

                for (var m = 0; m < filters.length; m++) {
                    var filter = filters[m];
                    value = filter.value;
                    condition = filter.condition;
                    operator = filter.operator;
                    type = filter.type;
                }
                let obj = {
                    dataField: filterDataField,
                    filterOperator: filterOperator,
                    condition: condition,
                    value: value,
                    type: type
                };
                objFilter.push(obj);
            }
        }
        return this.httpClient.get(URL_SERVICIOS + recurso);
    };

}
