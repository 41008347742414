import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';
import { Especie } from '../../models/especie.model';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
const PARAMS = new HttpParams({
    fromObject: {
        action: 'opensearch',
        format: 'json',
        origin: '*'
    }
});

@Injectable({ providedIn: 'root' })
export class EspecieService {
    constructor(
        private http: HttpClient
    ) { }

    almacenar(especie: Especie) {
        if (especie._id != null) {
            //actualizando
            let urla = URL_SERVICIOS + 'api/especies/' + especie._id;
            return this.http.put(urla, especie)
                .map((resp: any) => {
                    return resp;
                });
        } else {
            // creando
            return this.http.post(URL_SERVICIOS + 'api/especies', especie)
                .map((resp: any) => {
                    return resp;
                });
        }
    }
    cargarEspecie() {
        let url = URL_SERVICIOS + 'api/especies' + '?sorting=nombeRuta:asc';
        return this.http.get(url)
            .map((resp: any) => {
                return resp.especies;
            });
    }
    obtenerPorId(id: string) {
        if (id === null) {
            return of([]);
        }
        return this.http
            .get(URL_SERVICIOS + 'api/especies/' + id).pipe(
                map(response => response)
            );
    }
}
