import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Documentos',
    icon: 'mdi mdi-folder-multiple-outline',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [{
      path: '/documento/morvhmth/listado',
      title: 'Anexo 1',
      icon: '',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: []
    }]
  },
  {
    path: '',
    title: 'Catalogos',
    icon: 'mdi mdi-cube',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [{
      path: '/productor/list',
      title: 'Productores',
      icon: '',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: []
    }]
  },
    {
    path: '',
    title: 'Rutas',
    icon: 'mdi mdi-cube',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
      path: '/ruta/listado',
      title: 'Listado',
      icon: '',
      class: '',
      label: '',
      labelClass: '',
      extralink: false,
      submenu: []
    }
  ]
  }
];
