export { GlobalService } from './global.service';
export { Sv01Service } from './sv01/sv01.service';
export { ProductorService } from './productor/productor.service';
export * from './authentication.service';
export * from './data.service';
export * from './shared/validation.service';
export * from './tecnico/tecnico.service';
export * from './trampero/trampero.service';
export * from './ruta/ruta.service';
export * from './modulo/modulo.service';
export * from './especie/especie.service';
export * from './variedad/variedad.service';