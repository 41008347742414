import { Ruta } from './../../models/ruta.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

const PARAMS = new HttpParams({
    fromObject: {
        action: 'opensearch',
        format: 'json',
        origin: '*'
    }
});

@Injectable({ providedIn: 'root' })
export class RutaService {
    public url = URL_SERVICIOS + '/ruta';
    constructor(
        private http: HttpClient
    ) { }

    almacenar(ruta: any) {

        if (ruta.folio != null) {
            //actualizando
            let urla = URL_SERVICIOS + 'catRutas/actualizar/' + ruta.folio;
            return this.http.put(urla, ruta)
                .map((resp: any) => {
                    return resp;
                });
        } else {
            // creando
            return this.http.post(URL_SERVICIOS + 'api/ruta', ruta)
                .map((resp: any) => {
                    return resp;
                });
        }
    }
    search(term: string) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .get(this.url + '/search', { params: PARAMS.set('search', term) }).pipe(
                map(response => response)
            );
    }
    obtenerPorId(id: string) {
        if (id === null) {
            return of([]);
        }
        return this.http
            .get(URL_SERVICIOS + 'catRutas/obtener/' + id).pipe(
                map(response => response)
            );
    }
    obtenerSiguiente(idActual:any){
        if (idActual === null) {
            return of([]);
        }
        return this.http
            .get(URL_SERVICIOS + 'catRutas/obtenerSiguiente/' + idActual).pipe(
                map(response => response)
            );
    }
}
