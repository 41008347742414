import { Modulo } from '../../models/modulo.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
const PARAMS = new HttpParams({
    fromObject: {
        action: 'opensearch',
        format: 'json',
        origin: '*'
    }
});
@Injectable({ providedIn: 'root' })
export class ModuloService {

    totalModulos: number = 0;
    public url = URL_SERVICIOS + '/modulo';
    constructor(
        private http: HttpClient,
    ) { }

    almacenar(modulo: Modulo) {
        if (modulo._id != null) {
            // actualizando
            let url = URL_SERVICIOS + 'api/modulo/' + modulo._id;
            return this.http.put(url, modulo)
                .map((resp: any) => {
                    return resp;
                });
        } else {
            // creando
            return this.http.post(URL_SERVICIOS + 'api/modulo', modulo)
                .map((resp: any) => {
                    return resp;
                });
        }
    }
    search(term: string) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .get(this.url + '/search', { params: PARAMS.set('search', term) }).pipe(
                map(response => response)
            );
    }
    obtenerPorId(id: string) {
        if (id == null) {
            return of([]);
        }
        return this.http
            .get(URL_SERVICIOS + 'api/modulo/' + id).pipe(
                map(response => response)
            );
    }
    cargarModulo() {
        let url = URL_SERVICIOS + 'api/modulo' + '?sorting=nombre:asc';
        return this.http.get(url)
            .map((resp: any) => {
                return resp.modulo;
            });
    }
}
