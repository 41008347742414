import { LoginComponent } from './authentication/login/login.component';
import { AuthGuard } from './_guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FullComponent } from './layouts/full/full.component';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/starter', pathMatch: 'full' },
      {
        path: 'starter',
        loadChildren: './starter/starter.module#StarterModule'
      },
      {
        path: 'productor',
        loadChildren: './modules/productor/productor.module#ProductorModule'
      },
      {
        path: 'documento',
        loadChildren: './modules/documentos/documento.module#DocumentosModule'
      },
      {
        path: 'modulo',
        loadChildren: './modules/modulo/modulo.module#ModuloModule'
      },
      {
        path: 'tecnico',
        loadChildren: './modules/tecnico/tecnico.module#TecnicoModule'
      },
      {
        path: 'trampero',
        loadChildren: './modules/trampero/trampero.module#TramperoModule'
      },
      {
        path: 'ruta',
        loadChildren: './modules/ruta/ruta.module#RutaModule'
      },
      {
        path: 'especie',
        loadChildren: './modules/especie/especie.module#EspecieModule'
      },
      {
        path: 'variedad',
        loadChildren: './modules/variedad/variedad.module#VariedadModule'
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    redirectTo: '/starter'
  }
];
