import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';
import { GlobalService } from '@app/services/service.index';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  loginForm: any;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  estados:any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private _globalService: GlobalService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
    this.loginForm = this.createFormGroup();
  }
  createFormGroup() {
    return this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this._globalService.loadModulos()
      .subscribe(data => {
        this.estados = data;
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          console.log('Estatus',data.response);
          if(data.response){
            this.router.navigate([this.returnUrl]);
          }else{
            Swal('Error', data.message, 'error');
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
  onSelectState(state_id:any) {
    localStorage.setItem('currentState', JSON.stringify(state_id));
    //console.log(state_id);
    /*if (state_id) {
      this.obtenerMunicipios(state_id);
    } else {
      return;
    }*/
  }
}