import { Anexouno } from './../../models/anexouno.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

const PARAMS = new HttpParams({
    fromObject: {
        action: 'opensearch',
        format: 'json',
        origin: '*'
    }
});
import { URL_SERVICIOS } from '../../config/config';
@Injectable({
    providedIn: 'root'
})
export class Sv01Service {

    constructor(private httpClient: HttpClient) { }

    public url = URL_SERVICIOS + '/sv01';

    getSpeciesAndmunicipalitys<T>(filters: any = {}): Observable<any> {
        return this.httpClient.get<any>(`${this.url}/getSpeciesAndmunicipalitys`, { params: filters });
    }

    save(params: any): Observable<any> {
        return this.httpClient.post<any>(`${this.url}`, params);
    }
    search(term: string) {
        if (term === '') {
            return of([]);
        }
        return this.httpClient
            .get(this.url + '/search', { params: PARAMS.set('search', term) }).pipe(
                map(response => response[1])
            );
    }
    obtenerNumeroInscripcion(numeroInscripcion: string) {
        let url = URL_SERVICIOS + 'controlador/obtenerUnidadProduccionv2/' + numeroInscripcion;
        return this.httpClient.get(url)
            .pipe(map((resp: any) => resp.result));
    }
    guardarAnexo01(params: Anexouno) {
        let url = URL_SERVICIOS + '/controlador/registrar/anexo01';
        if (params.id > 0) {
            // actualizando
            //url += '?token=' + this._usuarioService.token;
            return this.httpClient.put(URL_SERVICIOS + 'controlador/actualizar/anexo01/' + params.id, params)
                .pipe(map((resp: any) => {
                    return resp;
                }));
        } else {
            // creando
            //url += '?token=' + this._usuarioService.token;
            return this.httpClient.post(url, params)
                .pipe(map((resp: any) => {
                    return resp;
                }));
        }
    }
    obtenerPorId(id: number) {
        if (id === 0) {
            return of([]);
        }
        return this.httpClient
            .get(URL_SERVICIOS + '/controlador/getById/anexo01/' + id).pipe(
                map(response => response)
            );
    }
}
