import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { URL_SERVICIOS } from '../config/config';
//import { UsuarioService } from '../usuario/usuario.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
const PARAMS = new HttpParams({
    fromObject: {
        action: 'opensearch',
        format: 'json',
        origin: '*'
    }
});
//import swal from 'sweetalert';
@Injectable({ providedIn: 'root' })
export class GlobalService {

    totalProductores: number = 0;
    public url = URL_SERVICIOS + '/productor';
    constructor(
        private http: HttpClient
    ) { }

    cargarOrganismo() {
        let url = URL_SERVICIOS + 'organismo' + '?sorting=nombre:asc';
        return this.http.get(url)
            .pipe(
                map((resp: any) => {
                    return resp.organismo;
                }));
    }
    loadModulos(){
        //http://api.sigmod.mx/auth/estados
        let url = URL_SERVICIOS + 'auth/estados';
        return this.http.get(url)
            .map((resp: any) => {
                return resp;
            });
    }
    cargarEstados() {
        let url = URL_SERVICIOS + 'controlador/listado/estados';
        return this.http.get(url)
            .pipe(map((resp: any) => {
                return resp.result;
            }));
    }
    cargarMunicipios(estado_id: string) {
        let url = URL_SERVICIOS + 'controlador/municipios/' + estado_id;
        return this.http.get(url)
            .pipe(map((resp: any) => {
                return resp.result;
            }));
    }
    cargarLocalidades(municipio_id: string) {
        let url = URL_SERVICIOS + 'controlador/localidades/' + municipio_id;
        return this.http.get(url)
            .pipe(map((resp: any) => {
                return resp.result;
            }));
    }
    search(term: string) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .get(this.url + '/search', { params: PARAMS.set('search', term) }).pipe(
                map(response => response)
            );
    }
    loadCapturista(){
        let url = URL_SERVICIOS + 'api/capturistas';
        return this.http.get(url)
            .map((resp: any) => {
                return resp;
            });
    }
}
