import { Trampero } from './../../models/trampero.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

const PARAMS = new HttpParams({
    fromObject: {
        action: 'opensearch',
        format: 'json',
        origin: '*'
    }
});

@Injectable({ providedIn: 'root' })
export class TramperoService {
    public url = URL_SERVICIOS + '/trampero';
    constructor(
        private http: HttpClient
    ) { }

    almacenar(trampero: Trampero) {

        if (trampero._id !== null) {
            //actualizando
            let urla = URL_SERVICIOS + 'api/trampero/' + trampero._id;
            return this.http.put(urla, trampero)
                .map((resp: any) => {
                    return resp;
                });
        } else {
            // creando
            return this.http.post(URL_SERVICIOS + 'api/trampero', trampero)
                .map((resp: any) => {
                    return resp;
                });
        }
    }
    search(term: string) {
        if (term === '') {
            return of([]);
        }
        return this.http
            .get(this.url + '/search', { params: PARAMS.set('search', term) }).pipe(
                map(response => response)
            );
    }
    obtenerPorId(id: string) {
        if (id == null) {
            return of([]);
        }
        return this.http
            .get(URL_SERVICIOS + 'api/trampero/' + id).pipe(
                map(response => response)
            );
    }
    cargarTrampero() {
        let url = URL_SERVICIOS + 'api/trampero' + '?sorting=nombre:asc';
        return this.http.get(url)
            .map((resp: any) => {
                return resp.trampero;
            });
    }
}
